import { UIStore } from './UIStore'

let store = null

export class Store {
  uiStore: UIStore

  constructor() {
    this.uiStore = new UIStore()
  }

  onMount = () => {
    this.uiStore.onMount()
  }
  onUnmount = () => {
    this.uiStore.onUnmount()
  }
}

export const initStore = () => {
  store = store != null ? store : new Store()
  return store
}
