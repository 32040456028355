import React, { Component } from 'react'

export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  Comp?: any
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  offsetXs?: number
  offsetSm?: number
  offsetMd?: number
  offsetLg?: number
  offsetXl?: number
}

export class Col extends Component<ColProps> {
  render() {
    const {
      className,
      xs,
      sm,
      md,
      lg,
      xl,
      offsetXs,
      offsetSm,
      offsetMd,
      offsetLg,
      offsetXl,
      Comp,
      ...rest
    } = this.props
    const classes = ['col', `col-${xs || 12}`]

    if (sm) {
      classes.push(`col-sm-${sm}`)
    }
    if (md) {
      classes.push(`col-md-${md}`)
    }
    if (lg) {
      classes.push(`col-lg-${lg}`)
    }
    if (xl) {
      classes.push(`col-xl-${xl}`)
    }

    if (offsetXs) {
      classes.push(`offset-${offsetXs}`)
    }
    if (offsetSm) {
      classes.push(`offset-sm-${offsetSm}`)
    }
    if (offsetMd) {
      classes.push(`offset-md-${offsetMd}`)
    }
    if (offsetLg) {
      classes.push(`offset-lg-${offsetLg}`)
    }
    if (offsetXl) {
      classes.push(`offset-xl-${offsetXl}`)
    }
    if (className) {
      classes.push(className)
    }

    return <div className={classes.join(' ')} {...rest} />
  }
}
