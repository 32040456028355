import React, { Component } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { IMenu } from '~models'
import styles from './styles.module.scss'
import { Icons } from '~libs/assets'

interface FooterMenu {
  title: string
  menu: IMenu[]
}

export interface FooterProps {
  footerMenu: FooterMenu[]
}

export class FooterComponent extends Component<FooterProps> {
  menu = (item: FooterMenu) => {
    return (
      <div key={item.title} className={[styles.inner_col, 'text-right'].join(' ')}>
        <h4>{item.title}</h4>
        {item.menu.map(menuItem => (
          <Link key={menuItem.title} to={`/${menuItem.page.post_name}`}>
            {menuItem.title}
          </Link>
        ))}
      </div>
    )
  }

  render() {
    const { footerMenu } = this.props
    return (
      <footer className={styles.footer}>
        <div className={['container', styles.footer_container].join(' ')}>
          <div className={styles.col} />
          <div className={styles.col}>{footerMenu.map(this.menu)}</div>
        </div>
      </footer>
    )
  }
}

const query = graphql`
  {
    wordpressAcfOptions {
      navigation {
        menu {
          title
          page {
            post_name
          }
        }
        footer {
          title
          menu {
            title
            page {
              post_name
            }
          }
        }
      }
    }
  }
`

interface QueryProps {
  wordpressAcfOptions: {
    navigation: {
      footer: {
        title
        menu: IMenu[]
      }[]
    }
  }
}

export const Footer = () => (
  <StaticQuery
    query={query}
    render={(data: QueryProps) => (
      <FooterComponent footerMenu={data.wordpressAcfOptions.navigation.footer} />
    )}
  />
)
