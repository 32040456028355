import React, { Component } from 'react'
import styles from './styles.module.scss'
import { Icons } from '~libs/assets'

export interface ModalProps {
  visible?: boolean
  onClose?: () => void
}

export interface ModalState {
  fullyHidden?: boolean
}

const AnimDuration = 400

export class Modal extends Component<ModalProps, ModalState> {
  state: ModalState = {}

  componentDidUpdate(lastProps) {
    if (!lastProps.visible && this.props.visible) {
      // Goes visible
      this.setState({ fullyHidden: false })
    } else if (lastProps.visible && !this.props.visible) {
      // Goes hidden
      setTimeout(() => this.setState({ fullyHidden: true }), AnimDuration)
    }
  }

  render() {
    const { children, visible, onClose } = this.props
    const { fullyHidden } = this.state
    const classes = [styles.base]
    if (visible) {
      classes.push(styles.visible)
    }
    return (
      <div className={classes.join(' ')}>
        <div className={styles.childrenWrapper}>
          {!fullyHidden && (
            <>
              <img className={styles.closeButton} src={Icons.close} onClick={onClose} />
              {children}
            </>
          )}
        </div>
      </div>
    )
  }
}
