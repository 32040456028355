import React, { Component } from 'react'
import styles from './styles.module.scss'
import { Checkbox } from '../Checkbox'

export interface DenyCheckboxProps {
  selected: boolean
  onChange: (boolean) => void
}

export class DenyCheckbox extends Component<DenyCheckboxProps> {
  render() {
    return (
      <div className={styles.wrapper}>
        <Checkbox selected={this.props.selected} onChange={this.props.onChange} />
      </div>
    )
  }
}
