import { withPrefix } from 'gatsby'

export const Icons: { [key: string]: string } = {
  arrow_down: withPrefix('/icons/arrow_down.svg'),
  check_mark: withPrefix('/icons/check_mark.svg'),
  close: withPrefix('/icons/close.svg'),
  error: withPrefix('/icons/error.svg'),
  facebook: withPrefix('/icons/facebook.svg'),
  home: withPrefix('/icons/home.svg'),
  logo: withPrefix('/icons/logo.png'),
}
