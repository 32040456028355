import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { HeaderLinks } from './HeaderLinks'
import styles from './styles.module.scss'

interface HeaderProps {}

export class Header extends Component<HeaderProps> {
  state = {
    showBurger: null,
  }

  headerRef = React.createRef<HTMLElement>()
  linksRef = React.createRef<HeaderLinks>()

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
    setTimeout(this.onResize, 50)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    if (!this.headerRef || !this.linksRef) {
      return
    }
    const header = findDOMNode(this.headerRef.current)
    const links = findDOMNode(this.linksRef.current)

    if (!header || !links) {
      return
    }

    const maxWidth = header.clientWidth
    const totalWidth = links.clientWidth

    const showBurger = maxWidth < totalWidth
    if (this.state.showBurger !== showBurger) {
      this.setState({ showBurger })
    }
  }

  render() {
    const { showBurger } = this.state

    const classes = [styles.header, 'container']
    if (showBurger) {
      classes.push(styles.header_small)
    }

    return (
      <header ref={this.headerRef} className={classes.join(' ')}>
        <HeaderLinks ref={this.linksRef} showBurger={showBurger} />
      </header>
    )
  }
}
