import React, { Component } from 'react'

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

export class Container extends Component<ContainerProps> {
  render() {
    const { className, ...rest } = this.props
    const classes = ['container']
    if (className) {
      classes.push(className)
    }
    return <div className={classes.join(' ')} {...rest} />
  }
}
