export const getImageSafely = (
  image: any,
  size: 'small' | 'medium' | 'large' = 'large',
) => {
  let mImage = image
  if (Array.isArray(image)) {
    mImage = image.length ? image[0] : null
  }

  console.log(mImage)
  return mImage && mImage.localFile && mImage.localFile.childImageSharp
    ? mImage.localFile.childImageSharp[size].src
    : null
}
