import React, { Component } from 'react'
import { Link } from 'gatsby'
import styles from './styles.module.scss'

export interface HeaderLinkProps {
  route: string
}

export class HeaderLink extends Component<HeaderLinkProps> {
  render() {
    const { route, children } = this.props

    return (
      <div className={styles.link_wrapper}>
        <Link
          to={route}
          getProps={({ isPartiallyCurrent }) => {
            return isPartiallyCurrent ? { className: styles.link_selected } : null
          }}
        >
          {children}
        </Link>
      </div>
    )
  }
}
