import { observable, action } from 'mobx'
import { onAnimationFrame } from '~libs/utils'

export class UIStore {
  @observable
  windowWidth: number

  @observable
  windowHeight: number

  onMount = () => {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  onUnmount = () => {
    window.removeEventListener('resize', this.onResize)
  }

  @action
  onResize = () => {
    onAnimationFrame(() => {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    })
  }
}
