import React, { Component } from 'react'
import styles from './styles.module.scss'

export interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  square?: boolean
  background?: string
}

export class Div extends Component<DivProps> {
  render() {
    const { className, square, background, style, ...rest } = this.props

    const classes = []
    if (square) {
      classes.push(styles.square)
    }
    if (!!background) {
      classes.push(styles.background)
    }

    if (className) {
      classes.push(className)
    }

    return (
      <div
        {...rest}
        className={classes.join(' ')}
        style={{ backgroundImage: `url(${background})`, ...(style || {}) }}
      />
    )
  }
}
