import React, { Component } from 'react'
import styles from './styles.module.scss'

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  secondary?: boolean
  text?: boolean
  selected?: boolean
  disabled?: boolean
  stylename?: string
}

export class Button extends Component<ButtonProps> {
  render() {
    const {
      text,
      secondary,
      selected,
      stylename,
      disabled,
      className,
      ...rest
    } = this.props
    const classes = [styles.base]
    if (text) {
      classes.push(styles.text)
    } else if (secondary) {
      classes.push(styles.secondary)
    } else {
      classes.push(styles.primary)
    }

    if (selected) {
      classes.push(styles.selected)
    }

    if (disabled) {
      classes.push(styles.disabled)
    }

    if (stylename) {
      classes.push(styles[stylename])
    }

    if (className) {
      classes.push(className)
    }
    return <button className={classes.join(' ')} {...rest} />
  }
}
