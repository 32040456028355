import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { IMenu } from '~models'
import { HeaderLink } from './HeaderLink'
import styles from './styles.module.scss'

interface LinksProps extends React.HTMLAttributes<HTMLDivElement> {
  showBurger: boolean
  menu: IMenu[]
  onClose
}

export class Links extends Component<LinksProps> {
  state = {
    menuOpen: false,
  }

  renderBurger = () => {
    const { menu } = this.props
    const { menuOpen } = this.state

    const wrapperClasses = [styles.burger_wrapper]
    const burgerClasses = [styles.burger]
    if (menuOpen) {
      wrapperClasses.push(styles.open)
      burgerClasses.push(styles.open)
    }

    return (
      <>
        <div className={wrapperClasses.join(' ')}>
          <div className={styles.burger_links}>
            {menu.map(item => (
              <HeaderLink key={item.page.post_name} route={`/${item.page.post_name}`}>
                {item.title}
              </HeaderLink>
            ))}
          </div>
        </div>

        <div
          className={burgerClasses.join(' ')}
          onClick={() => this.setState({ menuOpen: !menuOpen })}
        >
          <div className={styles.line} />
        </div>
      </>
    )
  }

  componentDidUpdate() {
    const { showBurger } = this.props
    const { menuOpen } = this.state
    if (!showBurger && menuOpen) {
      this.setState({ menuOpen: false })
    }
  }

  render() {
    const { menu, showBurger } = this.props
    const classes = [styles.links_wrapper]
    showBurger && classes.push(styles.hide)
    return (
      <>
        <div className={classes.join(' ')}>
          {menu.map(item => (
            <HeaderLink key={item.page.post_name} route={`/${item.page.post_name}`}>
              {item.title}
            </HeaderLink>
          ))}
        </div>
        {showBurger && this.renderBurger()}
      </>
    )
  }
}

const query = graphql`
  {
    wordpressAcfOptions {
      navigation {
        menu {
          title
          page {
            post_name
          }
        }
      }
    }
  }
`

interface IOptionsProps {
  wordpressAcfOptions: {
    navigation: {
      menu: IMenu[]
    }
  }
}

export class HeaderLinks extends Component<{ showBurger: boolean }> {
  render() {
    const { showBurger } = this.props
    return (
      <StaticQuery
        query={query}
        render={(data: IOptionsProps) => (
          <Links
            showBurger={showBurger}
            menu={data.wordpressAcfOptions.navigation.menu}
          />
        )}
      />
    )
  }
}
